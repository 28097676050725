<template>
  <div>
    <div>
      <p>
        Selecione o “Tipo de documento” e/ou “Ano”, depois clique em “Buscar”
        para ter acesso aos documentos.
      </p>
      <div class="pb-4">
        <b-form
          id="alter-form-search-document-atas"
          class="alter-form form-align"
        >
          <CustomDocumentsAtas class="w-100 mt-4">
            <template slot="typeDocument">
              <CustomGenericDocumentAtas>
                <template slot="one">
                  <div class="d-flex align-items-center p3">
                    TIPO DE DOCUMENTO:
                  </div>
                </template>
                <template slot="two">
                  <div
                    class="div-select-custom"
                    :class="isLoadCard ? 'div-load' : null"
                  >
                    <b-select
                      :disabled="isLoadCard"
                      class="select-custom"
                      :options="itemsTypesSelect"
                      v-model.lazy.trim="type"
                      v-on:change="onChangeType(type)"
                      size="lg"
                    />
                    <div
                      v-if="isLoadCard"
                      class="d-flex align-items-center icon-container"
                    >
                      <i class="fas fa-spinner fa-spin icon"></i>
                    </div>
                  </div>
                </template>
              </CustomGenericDocumentAtas>
            </template>
            <template slot="yearCompetence">
              <CustomGenericDocumentAtasYear>
                <template slot="one">
                  <div class="d-flex align-items-center p3">
                    ANO (OPCIONAL):
                  </div>
                </template>
                <template slot="two">
                  <div
                    class="div-select-custom"
                    :class="isLoadYear ? 'div-load' : null"
                    id="div-year"
                  >
                    <b-select
                      :disabled="isLoadYear || isLoadCard || !isSelectCard"
                      class="select-custom"
                      :options="itemsYears"
                      v-model.lazy.trim="year"
                      v-on:change="onChangeYear(year)"
                      size="lg"
                    />
                    <div
                      v-if="isLoadYear"
                      class="d-flex align-items-center icon-container"
                    >
                      <i class="fas fa-spinner fa-spin icon"></i>
                    </div>
                  </div>
                </template>
              </CustomGenericDocumentAtasYear>
            </template>
            <template slot="buttonSearch">
              <b-button
                class="button-custom"
                variant="primary"
                @click="onGetDocuments()"
                :disabled="!allowSearch"
              >
                Buscar
              </b-button>
            </template>
          </CustomDocumentsAtas>
        </b-form>
      </div>

      <b-card
        v-show="this.searched"
        border-variant="light"
        class="result-card"
        body-class="p-0"
      >
        <b-table
          class="documents-table"
          :items="items"
          :fields="fields"
          v-show="!this.loading"
          responsive
          show-empty
          empty-text="Nenhuma informação para exibir!"
        >
          <template v-slot:head(name)="">
            <div class="element-padding-left">Nome do Documento</div>
          </template>
          <template v-slot:head(download)="">
            <div class="text-center">
              Download
            </div>
          </template>
          <template v-slot:head(year)="">
            <div>
              Ano
            </div>
          </template>
          <template v-slot:head(excluir)="">
            <div class="text-center">Excluir</div>
          </template>
          <template #cell(name)="data">
            <div class="element-padding-left">{{ data.item.name }}</div>
          </template>
          <template #cell(download)="data">
            <div class="d-flex justify-content-center">
              <img
                class="pointer"
                src="@/assets/icons/file-download-outline.svg"
                data-toggle="tooltip"
                data-placement="top"
                @click="downloadDocument(data.item.download)"
              />
            </div>
          </template>
          <template #cell(excluir)="data">
            <div class="d-flex justify-content-center">
              <div class="border-delete">
                <div
                  @click="onExcluirDocument(data.item.excluir)"
                  class="custom-icon-delete"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Excluir arquivo"
                />
              </div>
            </div>
          </template>
        </b-table>
      </b-card>
      <div v-show="this.loading">
        <b-skeleton-table :rows="12" :columns="4"></b-skeleton-table>
      </div>
    </div>

    <b-button
      class="my-3 mr-3"
      variant="outline-primary"
      id="newButtonText"
      v-b-modal:alt-add-types-documents-atas-modal
    >
      Editar ou adicionar tipo de documento
    </b-button>
    <b-button
      v-b-modal:add-document-modal
      class="m-3"
      variant="primary"
      id="submitButtonText"
    >
      Enviar documento
    </b-button>
    <DocumentsAndAtasModal
      ref="alt-add-types-documents-atas-modal"
      :loadedFormDataOptions="this.itemsTypes"
      :onUpdateDocumentType="onUpdateDocumentType"
    />
    <AddDocumentTypeModal
      :initDocumentsTypesOptions="initDocumentsTypesOptions"
    />
    <AddDocumentModal :loadedFormDataOptions="this.itemsTypes" :onAddDocument="onAddDocument" />
    <SuccessModal
      ref="success-modal"
      description="Documento excluído."
      buttonText="Ok, entendi!"
      subdescription=""
    />
    <AttentionModal
      modalId="attention-modal"
      title="Atenção!"
      submitButtonText="Sim, excluir"
      cancelButtonText="Não tenho certeza"
      :onAction="onDeleteAttentionModal"
      ref="attention-modal"
      ><template slot="description">
        <div class="">
          <div>
            <p class="p1 mb-5 justify-content-center">
              Tem certeza que deseja excluir esse documento?
            </p>
          </div>
        </div>
      </template>
    </AttentionModal>
  </div>
</template>

<script>
import {
  validateField,
  requiredFieldsFilled,
} from "../../../../utils/validate/validate.js";
import { DocumentType } from "../../../../static-data/DocumentType";
import {
  deleteDocumentById,
  getDocumentsTypes,
  getListDocuments,
  getListYearsDocumentByType,
} from "../../../../services/documents_atas/documents-atas";
import { sheetUpload } from "../../../../services/document-upload/document-file-service";

import { downloadAxios } from "../../../../services/download/download-service";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";
import AddDocumentTypeModal from "../forms/AddDocumentTypeModal.vue";
import AddDocumentModal from "../forms/AddDocumentModal.vue";
import { isMobile } from "../../../../utils/mobile/mobile";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";
import { getMimeTypeByExtension } from "../../../../utils/File/mimeType"
import CustomGenericDocumentAtas from "../../../documents-atas/components/CustomGenericDocumentAtas.vue";
import CustomGenericDocumentAtasYear from "../../../documents-atas/components/CustomGenericDocumentAtasYear.vue";
import CustomDocumentsAtas from "../../../documents-atas/components/CustomDocumentsAtas.vue";
import DocumentsAndAtasModal from "./documents-atas/DocumentsAndAtasModal.vue";
import { createToast } from '../../../../components/toast/toast.js';

const DefaultForm = {
  fileType: null,
  crm: null,
  competency: null,
  files: null,
};

export default {
  props: ["onSubmitHandler"],
  components: {
    SuccessModal,
    AttentionModal,
    AddDocumentTypeModal,
    AddDocumentModal,
    CustomGenericDocumentAtas,
    CustomGenericDocumentAtasYear,
    CustomDocumentsAtas,
    DocumentsAndAtasModal,
  },
  mounted() {
    this.onInit();
  },
  computed: {
    DocumentType() {
      return DocumentType;
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Nome do Documento",
        },
        {
          key: "year",
          label: "Ano",
        },
        {
          key: "download",
          label: "Download",
        },
        {
          key: "excluir",
          label: "Excluir",
        },
      ],
      documentId: null,
      year: null,
      items: [],
      type: null,
      typeDocument: null,
      fileTypeOptions: [],
      itemsTypes: [],
      itemsTypesSelect: [],
      loading: false,
      formSubmited: false,
      searched: false,
      itemsYears: [],
      isLoadYear: false,
      allowSearch: false,
      isSelectCard: false,
      isLoadCard: true,
      form: {
        ...DefaultForm,
      },
      validations: {},
      validateField,
      requiredFieldsFilled,
    };
  },
  methods: {
    isMobile: isMobile,
    onAddDocument(){
      if (this.searched) {
        this.onGetDocuments(); 
      }
    },
    async onSubmit(e) {
      e.preventDefault();
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        try {
          this.globalLoading = true;
          await sheetUpload(this.form);
          this.globalLoading = false;
        } catch (error) {
          this.globalLoading = false;
          console.error("Error OnSubmit", error);
          createToast("Atenção", "Ocorreu um erro ao processar o arquivo.");
        }
        this.formSubmited = false;
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    async initDocumentsTypesOptions() {
      try {
        this.isLoadCard = true;
        this.itemsTypes = await getDocumentsTypes();
        this.itemsTypesSelect = this.buildSelectOptions(this.itemsTypes);
        this.isLoadCard = false;
        this.globalLoading = false;
      } catch (error) {
        this.isLoadCard = false;
        this.globalLoading = false;
        console.error("getDocumentsTypes", error);
      }
    },
    buildSelectOptions(types) {
      return [
        { value: null, text: "Selecione um tipo", disabled: true },
        ...types.map((value) => ({
          text: value.type,
          value: value,
        })),
      ];
    },
    async initListDocuments(type, date) {
      try {
        if (type || date) {
          this.searched = true;
          this.loading = true;
          const items = await getListDocuments(type.id, date);
          this.items = items;

          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        console.error("getListDocuments", error);
      }
    },
    onGetDocuments() {
      this.fields = this.fields.map((field) => {
        if (!this.typeDocument.showDate && field.key == "year") {
          field = { ...field, thClass: "d-none", tdClass: "d-none" };
        } else {
          delete field.thClass;
          delete field.tdClass;
        }
        return field;
      });
      this.initListDocuments(this.typeDocument, this.year);
    },
    async downloadDocument({ pathFile, name }) {
      const splitedPath = pathFile.split('.');
      const fileExtension = splitedPath[splitedPath.length -1].split('?')[0];
      this.loading = true;
      const downloadedFileBase64 = await downloadAxios(
        pathFile,
        name,
        this.$store.getters.isApp
      );

      var mimeType = getMimeTypeByExtension(fileExtension);

      anchorBase64Download(
        this.$store.getters.isApp,
        mimeType,
        name + "." + fileExtension,
        downloadedFileBase64
      );
      this.loading = false;
    },
    onChangeType(type) {
      this.typeDocument = type;
      this.initListYearsDocumentByType(this.typeDocument.id);
      this.allowSearch = true;
    },
    onChangeYear(year) {
      this.year = year;
    },
    async onExcluirDocument(documentId) {
      this.documentId = documentId;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    async onDeleteAttentionModal(action) {
      if (action) {
        await this.deleteDocument();
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.initListDocuments(this.typeDocument, this.year);
      }
    },
    async deleteDocument() {
      try {
        this.loading = true;
        await deleteDocumentById(this.documentId);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("deleteDocument", error);
      }
    },
    async initListYearsDocumentByType(typeId) {
      try {
        this.isLoadYear = true;
        this.isSelectCard = false;
        this.years = await getListYearsDocumentByType(typeId);
        this.itemsYears = this.buildSelectOptionsYears(this.years);
        this.isLoadYear = false;
        this.isSelectCard = true;
      } catch (error) {
        this.isSelectCard = true;
        this.isLoadYear = false;
        console.error("getListYearsDocumentByType", error);
      }
    },
    buildSelectOptionsYears(years) {
      let yearsAux = [];

      years.forEach((element) => {
        yearsAux.push({ id: element, value: element });
      });

      yearsAux.reverse();

      return [
        { value: null, text: "Selecione um ano" },
        ...yearsAux.map(({ id, value }) => ({
          text: id,
          value: value,
        })),
      ];
    },
    async onUpdateDocumentType(typeId) {
      if (
        this.typeDocument != null &&
        this.typeDocument != undefined &&
        this.typeDocument == typeId
      ) {
        await this.onInit();
        this.typeDocument = null;

        if (this.itemsTypes?.length > 0) {
          this.items = [];
        }
      } else {
        await this.onInit();
      }
    },
    async onInit() {
      await this.initDocumentsTypesOptions();
    },
  },
};
</script>

<style lang="scss" scoped>
.documents-table table {
  th,
  td {
    text-align: start;
  }
}
.form-template--navigation {
  width: 100rem;
}
.element-padding-left {
  padding-left: 2rem;
}
.pointer {
  cursor: pointer;
}
.result-card {
  margin: 1rem 0 2rem 0;
}
.text-size-mobile {
  margin: 0 2rem 0 0;
}
.form-align {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}
@media screen and (max-width: 800px) {
  .text-size-mobile {
    font-size: 1.1rem;
    margin: 0 0 1rem 0;
  }
  .alter-form div.form-row {
    padding: 0.5rem;
  }
  .form-align {
    align-items: start;
    flex-flow: column wrap;
  }
}

.div-select-custom {
  border: 1px solid #cacaca !important;
  text-decoration: none;
  border-radius: 5px;
}

.div-load {
  display: grid;
  grid-template-columns: auto 20px;
}

.select-custom {
  border: none;
}

.icon-container {
  padding-left: 5px;
}

.button-custom {
  height: 3.5rem;
  width: 7rem;
}

button.button-custom {
  font-size: small;
}

.border-delete .custom-icon-delete {
  margin-right: 12px;
  padding: 4px;
  background-image: url("../../../../assets/icons/trash-can-outline.svg");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-position: center;
  &:hover {
    background: rgba(213, 36, 83, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../../assets/icons/trash-can-outline-houver.svg");
    background-position: center;
  }
}
</style>

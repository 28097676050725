<template>
  <div>
    <AlterFormLayout
      modalId="add-document-modal"
      formTitle="Documentos e Atas"
      cancelButtonText="Cancelar"
      submitButtonText="Cadastrar novo documento"
      :onSubmitEvent="onSubmit"
    >
      <template #content>
        <DocumentForm :loadedFormDataOptions="$props.loadedFormDataOptions" />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sucesso!"
      buttonText="Ok, entendi!"
      subdescription="Documento adicionado."
    />
  </div>
</template>

<script>
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { saveDocuments } from "../../../../services/documents_atas/documents-atas";
import { createToast } from "../../../../components/toast/toast";
import DocumentForm from "./DocumentForm.vue";

export default {
  props: ["loadedFormDataOptions", "onAddDocument"],
  components: {
    AlterFormLayout,
    SuccessModal,
    DocumentForm,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onSubmit(formData) {
      try {
        this.loading = true;
        const files = formData.files;

        let yearToRequest =  (formData.showDate != null && formData.showDate ==  true) ? formData.year.getFullYear() : null;
        const fetchDocuments = files.map((file) =>
          saveDocuments({ ...formData, file, year: yearToRequest })
        );
        await Promise.all(fetchDocuments);

        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.loading = false;
        if (this.$props.onAddDocument) this.$props.onAddDocument();
      } catch (error) {
        console.error("onSubmit", error);
        this.loading = false;
        createToast("Atenção", "Ocorreu um erro ao salvar o tipo o documento.");
      }
    },
  },
};
</script>

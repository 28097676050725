<template>
  <div>
    <AlterFormLayout
      modalId="alt-add-types-documents-atas-modal"
      :formTitle="formTitle"
      :newItemButtonText="newItemButtonText"
      :cancelButtonText="cancelButtonText"
      :submitButtonText="submitButtonText"
      :onDeleteEvent="onDeleteForm"
      :onSubmitEvent="onSubmit"
      :onNewItemEvent="onNewItemEvent"
      :onCancelEvent="onCancelForm"
      attentionModalId="alt-add-types-documents-atas-modal"
      newItemModalId="add-types-documents-atas-modal"
      :disableEvent="false"
      :newItemVisible="newItemVisible"
      ref="alter-modal"
    >
      <template #content>
        <DocumentsAndAtasForm
          :loadedFormDataOptions="$props.loadedFormDataOptions"
          :onSelectTypeDocument="onSelectTypeDocument"
          :onUpdateDocumentType="onUpdateDocumentTypeHandler"
          :newRegister="newRegister"
        />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Alteração efetuada com sucesso."
      buttonText="Ok, entendi!"
      subdescription=""
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import DocumentsAndAtasForm from "./DocumentsAndAtasForm.vue";
import AlterFormLayout from "../../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../../components/base-modals/SuccessModal.vue";
import {
  deleteDocumentTypeById,
  updateDocumentType,
  saveDocumentType,
} from "../../../../../services/documents_atas/documents-atas";

export default {
  props: [
    "loadedFormDataOptions",
    "onUpdateDocumentType",
  ],
  components: {
    AlterFormLayout,
    DocumentsAndAtasForm,
    SuccessModal,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      protocol: "",
      newItemVisible: true,
      newRegister: false,
      formTitle: "",
      newItemButtonText: "",
      cancelButtonText: "",
      submitButtonText: "",
      typeId: null,
    };
  },
  methods: {
    initModalAlterDocument() {
      this.newRegister = false;
      this.formTitle = "Editar tipo de documento";
      this.newItemButtonText = "Adicionar tipo de documento";
      this.cancelButtonText = "Cancelar";
      this.submitButtonText = "Concluir";
    },
    initModalNewDocument() {
      this.newRegister = true;
      this.formTitle = "Adicionar tipo de documento";
      this.newItemButtonText = "";
      this.cancelButtonText = "Cancelar";
      this.submitButtonText = "Cadastrar novo tipo";
    },
    init() {
      this.newItemVisible = true;
      this.initModalAlterDocument()
    },
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async onSubmit(formData) {
      this.newItemVisible = true;
      this.initModalAlterDocument();

      if(formData.id == null || formData.id == undefined) {
        await saveDocumentType(formData);

        if (this.$props.onUpdateDocumentType) {
          this.$props.onUpdateDocumentType(null);
        }
      } else {
        await updateDocumentType(formData);
        this.$props.onUpdateDocumentType(null);
      }
      
      this.showSuccessModal();
    },
    async onNewItemEvent() {
      this.initModalNewDocument();
      this.newItemVisible = true;
    },
    onSelectTypeDocument() {
      this.newItemVisible = false; 
      this.newRegister = false;
    },
    onCancelForm() {
      this.newItemVisible = true; 
      this.initModalAlterDocument();
    },
    async onUpdateDocumentTypeHandler(typeId) {
      this.typeId = typeId;
      await this.onCloseModal();
    },
    async onCloseModal() {
      const modal = this.$refs["alter-modal"];
      if (modal != null || modal != undefined) {
        modal.doDelete();
      }
    },
    async onDeleteForm() {
      await deleteDocumentTypeById(this.typeId);

      if (this.$props.onUpdateDocumentType) {
        this.$props.onUpdateDocumentType(this.typeId);
      }
    },
  },
};
</script>

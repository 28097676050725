<template>
  <div>
    <AlterFormLayout
      modalId="add-document-type-modal"
      formTitle="Documentos e Atas"
      cancelButtonText="Cancelar"
      submitButtonText="Cadastrar novo tipo"
      :onSubmitEvent="onSubmit"
    >
      <template #content>
        <DocumentTypeForm />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sucesso!"
      buttonText="Ok, entendi!"
      subdescription="Documento adicionado."
    />
  </div>
</template>

<script>
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";
import { saveDocumentsTypes } from "../../../../services/documents_atas/documents-atas";
import { createToast } from "../../../../components/toast/toast";
import DocumentTypeForm from "./DocumentTypeForm.vue";

export default {
  props: ["loadedFormDataOptions", "initDocumentsTypesOptions"],
  components: {
    AlterFormLayout,
    SuccessModal,
    DocumentTypeForm,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onSubmit(formData) {
      try {
        this.loading = true;
        await saveDocumentsTypes(formData);
        this.$props.initDocumentsTypesOptions();
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.loading = false;
      } catch (error) {
        console.error("onSubmit", error);
        this.loading = false;
        createToast("Atenção", "Ocorreu um erro ao salvar o tipo o documento.");
      }
    },
  },
};
</script>

<template>
  <div>
    <b-card class="custom-card my-4" v-if="!$props.newRegister">
      <b-form-select
        v-model="selectedOption"
        :options="selectOptions"
        v-on:change="onChangeFormSelect"
        size="lg"
        class=" custom-select-head"
      />
    </b-card>

    <b-card class="custom-card my-4" v-show="showForm()" border-variant="light">
      <b-form-row>
        <b-col>
          Nome do Documento
        </b-col>
        <b-col>
          <b-form-input
            v-model.lazy.trim="form.documentName"
            placeholder="Nome do documento"
          />
        </b-col>
      </b-form-row>
      <b-form-row v-if="this.showDate">
        <b-col>
          ANO
        </b-col>
        <b-col>
          <datepicker
            :disabledDates="disabledDates"
            format="yyyy"
            minimum-view="year"
            name="datepicker"
            id="input-id"
            v-model="form.year"
            :class="$style.input"
            :state="onValidateField('year')"
            inputClass="input"
          ></datepicker>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É necessário inserir um anexo com a comprovação.'"
            :state="validateFieldFile('files')"
            :required="true"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import {
  requiredFieldsFilled,
  validateFieldFile,
} from "../../../../utils/validate/validate.js";
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import Datepicker from "vuejs-datepicker";

const DefaultForm = {
  type: null,
  files: [],
  documentName: null,
  year: null,
};

export default {
  props: ["loadedFormDataOptions", "newRegister"],
  components: { UploadFile, Datepicker },

  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    const setFormSelected = inject("setFormSelected");
    return { formData, setForm, setSubmitHandler, setFormSelected };
  },

  async mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      requiredFieldsFilled,
      validateFieldFile,
      selectedOption: null,
      showDate: true,
      selectOptions: [],
      disabledDates: {
        customPredictor: function (date) {
          const nextYear = new Date().getFullYear();
          if (date.getFullYear() > nextYear) {
            return true
          }
        }
      },
    };
  },
  methods: {
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      if (!this.$props.newRegister) {
        const loadedFormDataOptions = this.$props.loadedFormDataOptions;
        this.selectOptions = this.buildSelectOptions(loadedFormDataOptions);
      }
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        formSelected: false,
      };
    },
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;

      return !!value;
    },
    onGetShowDate(formData) {
      if (this.$props.loadedFormDataOptions != null && this.$props.loadedFormDataOptions != undefined) {
        const response = this.loadedFormDataOptions.find((item) => {
          return item.id == formData;
        });

        return response.showDate;
      }

      return true;
    },
    onValidateField(value){
      if (!this.showDate) {
        this.validations[value] = true;
        return true;
      }
      return this.validateField(value);
    },
    onChangeFormSelect(formData) {
      this.showDate = this.onGetShowDate(formData);
      this.onValidateField('year');
      if (!formData) {
        this.form = { ...DefaultForm };
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
          formSelected: false,
          showDate: this.showDate,
        };
        return;
      }
      const form = {
        ...this.form,
        type: formData,
        showDate: this.showDate,
      };
      this.form = form;
      this.setForm(form);
      this.setFormSelected(true);
    },

    buildSelectOptions(formDataOptions) {
      return [
        { value: null, text: "Selecione um tipo", disabled: true },
        ...formDataOptions.map((formData) => ({
          value: formData.id,
          text: formData.type,
        })),
      ];
    },
    showForm() {
      return this.selectedOption || this.$props.newRegister;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.label-right {
  width: 100%;
  text-align: right;
  padding-right: 2.2em;
}
.vdp-datepicker {
  width: 100%;
}
</style>
<style module>
input {
  text-align: right;
  float: right;
}
</style>

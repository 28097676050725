<template>
  <div>
    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          Tipo de Documento
        </b-col>
        <b-col>
          <b-form-input
            v-model.lazy.trim="form.type"
            :state="validateField('type')"
          />
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import {
  validateField,
  requiredFieldsFilled,
} from "../../../../utils/validate/validate.js";

const DefaultForm = {
  type: null,
};

export default {
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },

  async mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      validateField,
      requiredFieldsFilled,
    };
  },
  methods: {
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.label-right {
  width: 100%;
  text-align: right;
  padding-right: 2.2em;
}
</style>

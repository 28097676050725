<template>
  <div>
    <b-card v-show="!showForm()" class="custom-card my-4">
      <b-form-select
        v-model="selectedOption"
        :options="selectOptions"
        v-on:change="onChangeFormSelect"
        size="lg"
        class=" custom-select-head"
      />
    </b-card>

    <b-card class="custom-card my-4" v-show="showForm()" border-variant="light">
      <div :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">
        <div class="col">
          TIPO DO DOCUMENTO
        </div>
        <div class="col">
          <b-form-input
            v-model.lazy.trim="form.type"
            :state="validateField('type')"
          />
        </div>
        <div v-if="!this.$props.newRegister" class="d-flex justify-content-center">
          <div class="border-delete">
            <div
              @click="showDeleteModal()"
              class="custom-icon-delete"
              data-toggle="tooltip"
              data-placement="top"
              title="Excluir arquivo"
            />
          </div>
        </div>
      </div>
      <div :class="!this.$props.newRegister ? 'custom-div-year' : 'null'">
        <div class="mb-4" :class="isMobile() ? 'form-flex-column' : 'form-flex-row'">        
          <div class="col">
            APRESENTAR ANO?
          </div>
          <div class="col">
            <b-form-checkbox
              :class="isMobile() ? 'check-padding text-align-left' : 'ml-auto'"
              switch
              v-model="form.showDate"
              :state="validateCheckBox('showDate')"
            >
              {{ form.showDate ? "Sim" : "Não" }}
            </b-form-checkbox>
          </div>
        </div>
      </div>        
    </b-card>
    <attention-modal
      modalId="id-delete-document-type-modal"
      title="Atenção!"
      submitButtonText="Sim, exclusão"
      cancelButtonText="Não tenho certeza"
      :onAction="onExcluirDocument"
      ref="id-delete-document-type-modal"
    >
      <template slot="description">
        <div class="d-flex align-items-center justify-content-center mt-5 w-100">
          <div class="custom-div-msg">
            <div>
              <p class="p1 mb-5">
                Ao excluir uma pasta de tipo de documento, todos os arquivos anexados a ela também serão excluídos.
              </p>
            </div>
            <div>
              <p class="p1 mb-5">
                <strong v-text="`Tem certeza que deseja excluir a pasta de documento [${this.onGetTextType()}]?`"></strong>
              </p>
            </div>
          </div>
        </div>
      </template>
    </attention-modal>    
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import { isMobile } from "../../../../../utils/mobile/mobile";
import AttentionModal from "../../../../../components/base-modals/AttentionModal.vue";
import {
  requiredFieldsFilled,
  validateField,
  validateCheckBox,
} from "../../../../../utils/validate/validate.js";

const DefaultForm = {
  type: null,
};

export default {
  components: { AttentionModal },
  props: [
    "loadedFormDataOptions",
    "onSelectTypeDocument",
    "onUpdateDocumentType",
    "newRegister",
  ],
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      selectedOption: null,
      selectOptions: [],
      validateField,
      validateCheckBox,
      requiredFieldsFilled,
    };
  },
  methods: {
    isMobile: isMobile,
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      if (!this.$props.newRegister) {
        const loadedFormDataOptions = this.$props.loadedFormDataOptions;
        this.selectOptions = this.buildSelectOptions(loadedFormDataOptions);
      }   
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: false,
      };
    },
    showForm() {
      return this.selectedOption || this.$props.newRegister;
    },
    buildSelectOptions(formDataOptions) {
      return [
        { value: null, text: "selecione um tipo" },
        ...formDataOptions.map((formOption) => ({
          value: formOption,
          text: formOption.type,
        })),
      ];
    },
    onChangeFormSelect(formData) {
      this.onSelectTypeDocumentHandler();
      if (!formData) {
        this.form = { ...DefaultForm };
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
          onDeleteHandler: this.onDelete,
          formSelected: false,
        };

        return;
      }

      const form = {
        ...this.form,
        ...formData,
      };

      this.form = form;
      this.form.originalData = { ...this.form };

      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
        onDeleteHandler: this.onDelete,
        formSelected: true,
      };
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    onDelete() {
      return this.selectedOption;
    },
    onSelectTypeDocumentHandler() {
      if (this.$props.onSelectTypeDocument) {
        this.$props.onSelectTypeDocument();
      }
    },
    async onExcluirDocument(action) {
      if (action) {
        this.onUpdateDocumentTypeHandler(this.selectedOption.id);
        const modal = this.$refs["id-delete-document-type-modal"];
        modal.doDelete();
      }
    },
    onUpdateDocumentTypeHandler(typeId) {
      if (this.$props.onUpdateDocumentType) {
        this.$props.onUpdateDocumentType(typeId);
      }
    },
    showDeleteModal() {
      const modal = this.$refs["id-delete-document-type-modal"].$children[0];
      modal.show();
    },
    onGetTextType() {
      return this.selectedOption ? this.selectedOption.type : null;
    },
  },
};
</script>

<style lang="scss" scoped>
div.form-row {
  padding: 0.5rem;
  border-bottom: 1px solid $gray-300;
  &:last-child {
    border-bottom: none;
  }
}

input.form-control {
  text-align: right;
  border: 1px solid transparent;
}

select.custom-select {
  text-align: right;
  border: 1px solid transparent;
}
.alter-form div.card {
  padding: 0rem;
}
.card-body {
  padding: 0.25rem;
}
select.text-align-left {
  text-align: left;
  padding-left: 0px;
}
.check-padding {
  padding-top: 0.5rem;
}
.form-flex-column {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem 0rem 0rem;
}
.form-flex-row {
  display: flex;
  flex-direction: row;
  padding: 1rem 0rem 0rem 0rem;
}

.border-delete .custom-icon-delete {
  margin-right: 12px;
  padding: 4px;
  background-image: url("../../../../../assets/icons/trash-can-outline.svg");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-position: center;
  &:hover {
    background: rgba(213, 36, 83, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../../../assets/icons/trash-can-outline-houver.svg");
    background-position: center;
  }
}

.custom-div-year {
  padding-right: 6rem;
}

.custom-div-msg {
  text-align: center;
  width: 37.8rem;
}
</style>
